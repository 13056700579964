<template>
  <div class="">
    <div v-if="returnMessage" class="msg_overlay text-center">
      <h3 :class="classMessage">
        <i
          class="bi bi-bell"
          style="font-size: 73px; display: block"
          :class="classMessage"
        ></i
        ><br />{{ returnMessage }}
      </h3>
    </div>

    <nav class="navbar sticky-top navbar-dark">
      <img src="/banner2.png" class="img-fluid" style="" />
      <div class="container-fluid">
        <a class="navbar-brand"
          ><span style="font-size: 0.9rem">Welcome</span>
          <h4 style="color: #fff !important">
            {{ $auth.user.displayName }}
          </h4></a
        >
        <div class="float-end mx-3">
          <button
            type="button"
            class="btn btn-sofcot"
            id="tools_user"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-tools"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="tools_user"
          >
            <div>
              <a
                class="dropdown-item"
                :href="
                  'https://21sofcot.urevent.fr/Extract/all_contacts/' +
                  $auth.user.iduser
                "
                ><i class="bi bi-download"></i
                ><span class="px-2">Download your contact list</span></a
              >
            </div>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item" href="#" @click="$auth.logout"
                ><i class="bi bi-box-arrow-right"></i
                ><span class="px-2">logout</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container-fluid pt-3" style="margin-bottom: 140px">
      <div class="mb-3">
        <input
          type="search"
          class="form-control"
          id="searchFormControlInput1"
          placeholder="Find Lead"
          v-model="query"
        />
      </div>

      <button
        type="button"
        class="btn btn_myicon_success"
        id="readBarcode"
        @click="startScanner"
        v-if="editMode == null"
      >
        <i class="bi bi-upc-scan" style="font-size: 53px"></i>
      </button>
      <div v-if="Scans && Scans.length">
        <div v-for="filt in filteruser" :key="filt.idscan" class="pt-2">
          <div class="card mb-3">
            <div class="accordion-header" @click="switchOpen(filt)">
              <button
                class="accordion-button"
                type="button"
                aria-expanded="true"
                :class="openMode == filt ? '' : 'collapsed'"
              >
                {{ filt.registered_firstname.charAt(0)}}. {{ filt.registered_lastname }}
              </button>
            </div>
            <div v-if="openMode == filt">
              <div class="card-body">
                <p class="card-text muted">
                  Fullname<br /><b
                    >{{ filt.registered_firstname }}
                    {{ filt.registered_lastname }}</b
                  >
                </p>
                <p class="card-text">
                  Mail<br /><b>{{ filt.registered_mail }}</b>
                </p>
                <p class="card-text">
                  Country<br /><b>{{ filt.registered_country }}</b>
                </p>
                <p class="card-text">
                  City<br /><b>{{ filt.registered_city }}</b>
                </p>
                <p class="card-text">
                  Profession<br /><b>{{ filt.registered_function }}</b>
                </p>
                <p class="card-text">
                  Society<br /><b>{{ filt.registered_society_name }}</b>
                </p>
                <p class="card-text" v-if="editMode != filt">
                  Comments<br /><b>"{{ filt.scan_coments }}"</b>
                </p>
                <div class="form-floating" v-if="editMode === filt">
                  <textarea
                    class="form-control"
                    v-model="filt.scan_coments"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style="height: 100px; font-weight:300"
                  ></textarea>
                  <label for="floatingTextarea2">Ajoutez un texte</label>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-around">
                <button
                  type="button"
                  class="btn btn_icontools_green"
                  v-if="editMode === filt"
                  @click="editScan(filt)"
                >
                  <i class="bi bi-check2"></i>
                </button>
                <button
                  type="button"
                  class="btn btn_myicon"
                  v-if="editMode === filt"
                  @click="hideEdit"
                >
                  <i class="bi bi-x"></i>
                </button>
                <button
                  type="button"
                  class="btn btn_icontools_green"
                  v-if="editMode != filt"
                  @click="showEdit(filt)"
                >
                  <i class="bi bi-pencil"></i>
                </button>

                <button
                  type="button"
                  class="btn btn_icontools_green"
                  v-if="webShareApiSupported"
                  @click="shareViaWebShare(filt)"
                >
                  <i class="bi bi-share-fill"></i>
                </button>

                <button
                  type="button"
                  class="btn btn_myicon"
                  v-if="editMode != filt"
                  @click="showmodalDel(filt)"
                >
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="py-5 px-3 text-center">
        <p>
          You don't have any leads in your list yet. Click on the
          button at the bottom right and scan a visitor's badge to add your first
          add your first lead.
        </p>
      </div>
    </div>
    <!-- use the modal component, pass in the prop -->
    <EscanModal
      v-if="showModal"
      @close="showModal = false"
      v-bind:typemodal="typemodal"
      v-bind:contentmodal="contentmodal"
      v-bind:ModalMessage="ModalMessage"
      v-bind:classmodalMessage="classmodalMessage"
      v-on:delScan="delScan"
    >
      <h3 slot="header">custom header</h3>
    </EscanModal>
  </div>
</template>

<script>
import EscanModal from "./EscanModal";
export default {
  data() {
    return {
      editMode: null,
      openMode: null,
      query: "",
      showModal: false,
      typemodal: null,
      contentmodal: null,
      ModalMessage: null,
      classmodalMessage: null,
    };
  },

  components: {
    EscanModal,
  },
  props: ["Scans", "returnMessage", "classMessage"],
  computed: {
    filteruser: function () {
      return this.Scans.filter((s) =>
        s.registered_lastname.toLowerCase().includes(this.query.toLowerCase())
      );
    },
    webShareApiSupported() {
      return navigator.share;
    },
  },
  methods: {
    shareViaWebShare: function (filt) {
      navigator.share({
        Nom: filt.registered_lastname,
        Prénom: filt.registered_firstname,
        url: "URL to be shared",
      });
    },

    showmodalDel(scan) {
      this.ModalMessage = "Are you sure you want to delete this lead?";
      this.classmodalMessage = "warning";
      this.typemodal = "delete";
      this.contentmodal = scan;
      this.showModal = true;
    },

    startScanner() {
      this.$emit("showScanner");
    },

    editScan(scan) {
      this.$emit("editerScan", {
        idscan: scan.idscan,
        scan_coments: scan.scan_coments,
      });
      this.editMode = null;
    },

    delScan(idscan) {
      this.$emit("deleteScan", { idscan: idscan.idscan });
      this.showModal = false;
    },

    showEdit(scan) {
      this.editMode = scan;
    },

    switchOpen(scan) {
      if (this.openMode == scan) {
        this.openMode = null;
      } else {
        this.openMode = scan;
      }
    },

    hideEdit() {
      this.editMode = null;
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>